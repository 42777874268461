#lp {

  #header-bg {
    position: relative;
    background-image: none;
    position: absolute;
    width: 100%;
    z-index: 100;
  }

  .header-sub {
    position: relative;
    background: #75b000;
    color: #fff;
    height: 40px;
    line-height: 40px;
    font-weight: bold;
    width: 100%;
    overflow: hidden;
    text-align: center;
  }

  #main {
    position: relative;
    text-align: center;
    //padding: 240px 0 0;
    box-sizing: border-box;
    .logo {
      position: absolute;
      width: 100%;
      top: 10px;
    }
    .read {
      position: absolute;
      width: 100%;
      top: 100px;
      z-index: 1000;
    }
    .subread {
      position: absolute;
      top: 230px;
      width: 100%;
      text-align: center;
      z-index: 1000;
    }

    #animation {
      height: 770px;
    }
    #keycontent {


      &.night {
        .fuji {
          background: url(../img/lp/anime_night/back_fuji.png) repeat-x center bottom;
        }
      }
      .fuji {
        height: 657px;
        padding: 229px 0 0;
        box-sizing: border-box;
        background: url(../img/lp/anime/back_fuji.png) repeat-x center bottom;
      }


      .pop {
        display: none;
      }

    }
  }

  #posting {
    position: relative;
    padding: 40px 0 60px;

    .title {
      padding: 0 0 30px;
    }

    .images {
      overflow: hidden;
      width: 750px;
      margin: 0 auto;
      .left {
        float: left;
      }
      .right {
        float: right;
      }
    }

    .list {
      position: relative;
      padding: 40px 0 0;
      .block {
        font-size: 24px;
        overflow: hidden;
        margin: 0 0 10px;
        color: #86471d;
        font-weight: bold;
        .left {
          float: left;
          width: 50px;
        }
        .right {
          float: left;
        }
      }
      .notes {
        padding: 20px 0 0;
        color: #6e5531;
        line-height: 22px;
      }
    }

    #map {

      .title {
        padding: 30px 0 0;
      }

      .map {
        position: relative;
        top: -20px;
      }

      .desc {
        line-height: 22px;
      }

    }

  }

  #case {
    position: relative;
    padding: 60px 0 0;

    .title {
      padding: 0 0 35px;
    }

    .map-wrapper {
      position: relative;
      width: 750px;
      margin: 0 auto;
      overflow: hidden;
      padding:  0 0 70px;
      .left {
        float: left;
        width: 360px;
      }
      .right {
        float: right;
        width: 360px;
      }
      .icon {
        position: absolute;
        width: 100%;
        padding: 0;
        z-index: 10;
        text-align: center;
        bottom: 0;
      }
    }

    .desc {
      position: relative;
      padding: 10px 0 30px;
      overflow: hidden;
      .block {
        margin: 0 30px 30px 0;
        float: left;
        width: 300px;
        &.last {
          margin-right: 0;
        }
      }
      .case {
        background: #009de7;
        padding: 0 0 0 10px;
        font-size: 16px;
        margin: 0 0 20px;
      }
    }

    .read {
      padding: 0 0 20px;
    }

    .example {
      position: relative;
      overflow: hidden;
      .block {
        border: 1px solid #d19a6b;
        padding: 20px 27px;
        background: #fff;
        margin: 0 40px 40px 0;
        width: 460px;
        height: 260px;
        box-sizing: border-box;
        float: left;
        &.c2 {
          margin-right: 0;
        }
      }
      .case {
        background: #d40077;
        text-align: center;
      }
      .que {
        height: 36px;
        line-height: 36px;
        padding: 0;
      }
      .ans {
        line-height: 22px;
      }
    }

    .case {
      height: 30px;
      line-height: 30px;
      color: #fff;
      margin: 0 0 5px 0;
      font-weight: bold;
    }

    .que {
      font-weight: bold;
      color: #4e2901;
      padding: 0 0 5px;
      font-size: 16px;
      line-height: 24px;
    }

    .ans {
      padding: 15px 0 0;
      line-height: 24px;
    }

  }

  #qa {
    position: relative;
    padding: 0 0 30px;
    .icon {
      padding: 0 0 20px;
    }
    .block {
      margin: 0 0 30px;
    }

    .label {
      width: 80px;
      float: left;
      padding: 0 0 0 10px;
      box-sizing: border-box;
    }
    .txt {
      float: left;
      width: 880px;
      line-height: 24px;
    }

    .ans {
      padding: 0 0 10px;
      overflow: hidden;
      .txt {
        color: #4e2901;
        font-weight: bold;
        font-size: 21px;
        line-height: 36px;
        height: 36px;
      }
    }

    .que {
      padding: 20px 0 0;
      overflow: hidden;
    }

  }

  .contact-icon {
    position: absolute;
    width: 100%;
    z-index: 10;
    bottom: -21px;
    text-align: center;
  }


  .services {
    position: relative;
    background: #fff;
    padding: 35px 0 0;
    height: 280px;
    box-sizing: border-box;
    .service-wrapper {
      position: relative;
      overflow: hidden;
    }
    .s {
      height: 60px;
      line-height: 60px;
      text-align: center;
      color: #fff;
      width: 320px;
      float: left;
      //display: table;
      font-weight: bold;
      &.first {
        background: #009ee7;
      }
      &.second {
        background: #d40077;
      }
      &.third {
        background: #ffcc00;
        color: #86471d;
      }
    }

    .contact-wrapper {
      position: relative;
      background: #fff;
      padding: 155px 0 0;

      .tel, .mail {
        position: absolute;
        top: 20px;
        z-index: 11;
      }

      .tel {
        position: absolute;
        left: 0;
      }

      .mail {
        right: 0;
      }

      .icon {
        position: absolute;
        width: 100%;
        z-index: 10;
        text-align: center;
        top: -10px;
      }

      .info {
        color: #000;
        padding: 4px 0 0;
        text-align: center;
        line-height: 12px;
        font-size: 12px;
      }
    }
  }

  #footer {
    height: 70px;
    .logo {
      padding: 15px 0 0;
      position: absolute;
      z-index: 10;
    }
    .copyright {
      position: relative;
      top: 0;
      right:0;
      text-align: right;
      line-height: 70px;
      height: 70px;
    }
  }
}