body, figure {
  margin: 0;
  padding: 0;
}

#animation {
  position: relative;
  width: 100%;
  height: 541px;
  overflow: hidden;
}

#keycontent {

  .parts {
    position: relative;
    height: 541px;
  }

  &.night {

    .fuji {
      background: url(../img/top/anime_night/back_fuji.png) repeat-x center bottom;
    }

    .bill {
      background: url(../img/top/anime_night/back_bill.png) repeat-x center bottom;
    }

    .camp {
      background: url(../img/top/anime_night/parts_camp.png) repeat-x center bottom;
    }

    .house {
      background: url(../img/top/anime_night/parts_house.png) repeat-x center bottom;
    }

    .rail {
      background: url(../img/top/anime_night/back_rail.png) repeat-x center bottom;
    }

    .kikyu {
      background: url(../img/top/anime_night/parts_kikyu.png) no-repeat;
    }

    .track1 {
      background: transparent url(../img/top/anime_night/parts_track1.png) no-repeat top left;
    }

    .car1 {
      background: transparent url(../img/top/anime_night/parts_car.png) no-repeat top left;
    }

    .bike1 {
      background: transparent url(../img/top/anime_night/parts_bike1.png) no-repeat top left;
    }

    .bike2 {
      background: transparent url(../img/top/anime_night/parts_bike2.png) no-repeat top left;
    }

    .bird1 {
      background: transparent url(../img/top/anime_night/parts_bird.png) no-repeat top left;
    }

    .bird2 {
      background: transparent url(../img/top/anime_night/parts_bird2.png) no-repeat top left;
    }

    .bicycle1 {
      background: transparent url(../img/top/anime_night/parts_bicycle1.png) no-repeat top left;
    }

    .bicycle2 {
      background: transparent url(../img/top/anime_night/parts_bicycle2.png) no-repeat top left;
    }

    .man1 {
      background: transparent url(../img/top/anime_night/parts_man1.png) no-repeat top left;
    }

    .man2 {
      background: transparent url(../img/top/anime_night/parts_man2.png) no-repeat top left;
    }


    .comp {
      background: url(../img/top/anime_night/parts_company.png) no-repeat;
    }

    .kj {
      background: url(../img/top/anime_night/parts_kj.png) no-repeat;
    }
  }

  .pop {
    position: absolute;
    top: -10px;

    &.huki1 {
      width: 96px;
      height: 121px;
      background-image: url(../img/top/anime/huki_pos.png);
    }

    &.huki2 {
      left: 40px;
      width: 96px;
      height: 121px;
      background-image: url(../img/top/anime/huki_event.png);
    }

    &.huki3 {
      width: 97px;
      height: 112px;
      background-image: url(../img/top/anime/huki_company.png);
    }

    &.huki4 {
      width: 96px;
      height: 121px;
      background-image: url(../img/top/anime/huki_blog.png);
    }

    &.huki5 {
      width: 97px;
      height: 113px;
      background-image: url(../img/top/anime/huki_cre.png);
    }

    &.huki6 {
      width: 97px;
      height: 121px;
      background-image: url(../img/top/anime/huki_direct.png);
    }

    &.huki7 {
      width: 97px;
      height: 112px;
      background-image: url(../img/top/anime/huki_ass.png);
      top: -40px;
    }
    &:hover {
      background-position-y: bottom;
    }
  }


  .fuji {
    background: url(../img/top/anime/back_fuji2.png) repeat-x center bottom;
    width: 100%;
    height: 428px;
  }

  .bill {
    background: url(../img/top/anime/back_bill.png) repeat-x center bottom;
    width: 100%;
    height: 428px;
    z-index: 1000;
    position: absolute;
  }

  .camp {
    background: url(../img/top/anime/parts_camp.png) repeat-x center bottom;
    width: 100%;
    height: 541px;
    z-index: 100;
    position: absolute;
  }

  .house {
    background: url(../img/top/anime/parts_house.png) repeat-x center bottom;
    width: 100%;
    height: 126px;
    z-index: 110;
    top: 300px;
    position: absolute;
  }

  .rail {
    background: url(../img/top/anime/back_rail.png) repeat-x center bottom;
    width: 100%;
    top: 437px;
    height: 23px;
    z-index: 1100;
    position: absolute;
  }

  .shinkansen {
    position: absolute;
    top: 342px;
    left: -508px;
    z-index: 10;
  }

  .cloud {
    position: absolute;
    z-index: 10;
  }

  .cloud1 {
    top: 30px;
    left: -10px;
  }

  .cloud2 {
    top: 200px;
    left: 50px;
  }

  .cloud3 {
    top: 20px;
    left: 30%;
  }

  .cloud4 {
    top: 80px;
    left: 60%;
  }

  .cloud5 {
    top: 220px;
    left: 65%;
  }

  .cloud6 {
    top: 100px;
    left: 85%;
  }

  .cloud7 {
    top: 250px;
    left: 95%;
  }

  .cloud8 {
    top: 0px;
    left: 15%;
  }

  .cloud9 {
    top: 80px;
    left: 72%;
  }

  .cloud10 {
    top: 30px;
    left: 45%;
  }

  .plane {
    position: absolute;
    z-index: 110;
    left: -243px;
    top: 100px;
  }

  .kikyu {
    position: absolute;
    background: url(../img/top/anime/parts_kikyu.png) no-repeat;
    z-index: 150;
    width: 43px;
    //height: 159px;
    height: 158px;
    left: 100%;
    top: 50px;
  }

  .huki5 {
    position: absolute;
    z-index: 1060;
    margin-left: 20px;
  }

  .track1 {
    position: absolute;
    z-index: 1170;
    top: 280px;
    left: -200px;
    //left: 50%;
    width: 200px;
    height: 193px;
    display: block;
    //overflow: hidden;
    background: transparent url(../img/top/anime/parts_track1.png) no-repeat top left;
  }

  .huki2 {
    position: absolute;
    z-index: 1060;
    margin-left: 20px;
  }

  .car1 {
    position: absolute;
    z-index: 1171;
    top: 435px;
    left: 100%;
    width: 160px;
    height: 70px;
    display: block;
    //overflow: hidden;
    background: transparent url(../img/top/anime/parts_car.png) no-repeat top left;
  }

  .bike1 {
    position: absolute;
    z-index: 1160;
    top: 370px;
    left: -165px;
    //left: 50%;
    width: 165px;
    height: 100px;
    display: block;
    overflow: hidden;
    background: transparent url(../img/top/anime/parts_bike1.png) no-repeat top left;
  }

  .bike2 {
    position: absolute;
    z-index: 1180;
    top: 297px;
    left: 100%;
    //left: 50%;
    width: 275px;
    height: 208px;
    display: block;
    overflow: hidden;
    background: transparent url(../img/top/anime/parts_bike2.png) no-repeat top left;
  }

  .bird1 {
    position: absolute;
    z-index: 1300;
    top: 220px;
    left: -158px;
    width: 81px;
    height: 43px;
    display: block;
    overflow: hidden;
    background: transparent url(../img/top/anime/parts_bird.png) no-repeat top left;
  }

  .bird2 {
    position: absolute;
    z-index: 1250;
    top: 220px;
    left: 100%;
    width: 70px;
    height: 52px;
    display: block;
    overflow: hidden;
    background: transparent url(../img/top/anime/parts_bird2.png) no-repeat top left;
  }

  .bicycle1 {
    position: absolute;
    z-index: 1080;
    top: 230px;
    left: -100px;
    width: 100px;
    height: 210px;
    display: block;
    //overflow: hidden;
    background: transparent url(../img/top/anime/parts_bicycle1.png) no-repeat top left;
  }

  .huki1 {
    position: absolute;
    z-index: 1081;
  }

  .bicycle2 {
    position: absolute;
    z-index: 1085;
    top: 237px;
    left: 100%;
    width: 112px;
    height: 214px;
    display: block;
    //overflow: hidden;
    background: transparent url(../img/top/anime/parts_bicycle2.png) no-repeat top left;
  }

  .huki6 {
    position: absolute;
    z-index: 1086;
  }

  .man1 {
    position: absolute;
    z-index: 1095;
    top: 375px;
    left: 100%;
    width: 70px;
    height: 80px;
    display: block;
    overflow: hidden;
    background: transparent url(../img/top/anime/parts_man1.png) no-repeat top left;
  }

  .man2 {
    position: absolute;
    left: 40%;
    z-index: 1070;
    top: 245px;
    width: 78px;
    height: 180px;
    display: block;

    background: transparent url(../img/top/anime/parts_man2.png) no-repeat top left;
  }

  .post_o {
    position: absolute;
    z-index: 1065;
    left: 60%;
    margin-left: 50px;
    top: 360px;
  }

  .post_p {
    position: absolute;
    z-index: 1065;
    left: 20%;
    margin-left: 50px;
    top: 360px;
  }

  .post_r {
    position: absolute;
    z-index: 1065;
    left: 40%;
    margin-left: 50px;
    top: 360px;
  }

  .comp {
    position: absolute;
    background: url(../img/top/anime/parts_company.png) no-repeat;
    height: 182px;
    width: 130px;
    z-index: 1050;
    left: 25%;
    top: 235px;
  }

  .kj {
    position: absolute;
    background: url(../img/top/anime/parts_kj.png) no-repeat;
    //height: 196px;
    width: 234px;
    z-index: 1050;
    left: 68%;
    top: 275px;
    height: 145px;
  }

}


