@charset "UTF-8";
/*
YUI 3.18.1 (build f7e7bcb)
Copyright 2014 Yahoo! Inc. All rights reserved.
Licensed under the BSD License.
http://yuilibrary.com/license/
*/
html {
  color: #000;
  background: #FFF; }

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

fieldset, img {
  border: 0; }

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
  font-weight: normal; }

ol, ul {
  list-style: none; }

caption, th {
  text-align: left; }

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal; }

q:before, q:after {
  content: ''; }

abbr, acronym {
  border: 0;
  font-variant: normal; }

sup {
  vertical-align: text-top; }

sub {
  vertical-align: text-bottom; }

input, textarea, select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  *font-size: 100%; }

legend {
  color: #000; }

#yui3-css-stamp.cssreset {
  display: none; }

.cf:before,
.cf:after {
  content: " ";
  display: table; }

.cf:after {
  clear: both; }

.cf {
  *zoom: 1; }

* {
  outline: none; }

img {
  vertical-align: top;
  border: none; }

a {
  text-decoration: none; }
  a.opa:hover {
    opacity: 0.7; }

.crumb {
  background: #9f6947;
  color: #ffffff;
  font-weight: bold;
  line-height: 35px;
  height: 35px; }
  .crumb a {
    color: #fff !important; }
    .crumb a:hover {
      text-decoration: underline; }

.center {
  text-align: center; }

.dot {
  height: 2px;
  background: url(../img/sp/common/dot.png) center top;
  background-size: auto 2px; }

.slick-dots {
  position: relative;
  text-align: center; }
  .slick-dots li {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #dec5b0;
    border-radius: 5px;
    margin: 0 4px 0; }
    .slick-dots li.slick-active {
      background-color: #009320; }
    .slick-dots li button {
      opacity: 0; }

.contact {
  position: relative; }
  .contact.sub {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1000; }
  .contact a {
    display: block;
    height: 100%; }
    .contact a span {
      font-size: 0; }
  .contact .wrap {
    overflow: hidden;
    width: 375px; }
  .contact .tel {
    float: left; }
  .contact .mail {
    float: left; }

.green {
  color: #009320; }

.wrapper {
  position: relative;
  width: 960px;
  margin: 0 auto; }

.page-title {
  position: relative;
  padding: 40px 0;
  text-align: center; }

p.pg {
  padding: 0 0 25px; }

h3.title {
  position: relative;
  padding: 15px 0 5px;
  font-size: 20px;
  font-weight: bold; }

.ads {
  padding: 60px 0 80px;
  text-align: center;
  overflow: hidden; }
  .ads .ad {
    width: 300px;
    float: left;
    margin: 0 30px 0 0; }
    .ads .ad.last {
      margin: 0; }
    .ads .ad img {
      width: 100%; }
    .ads .ad .note {
      padding: 20px 0 0;
      text-align: center; }

.slider {
  position: relative; }
  .slider .arrow-left {
    position: absolute;
    left: 0;
    top: 62px;
    z-index: 10;
    cursor: pointer; }
  .slider .arrow-right {
    position: absolute;
    right: 0;
    top: 62px;
    z-index: 10;
    cursor: pointer; }
  .slider .sv {
    width: 240px;
    margin: 0 auto; }
  .slider .desc {
    padding: 10px 0 0;
    height: 100px;
    line-height: 1.5;
    box-sizing: border-box; }

.tr {
  border-collapse: separate;
  /* ←ココで余白 */
  border-spacing: 1px;
  /* ←ココで余白 */ }
  .tr.center .txt {
    padding: 0;
    text-align: center; }

.head .td .txt {
  text-align: center;
  padding: 0; }

.total {
  padding: 10px 0 20px; }

.td {
  position: relative;
  padding: 0 3px 3px 0;
  display: table-cell;
  height: 30px;
  font-size: 12px;
  vertical-align: middle;
  line-height: 30px; }
  .td.h50 {
    height: 50px;
    line-height: 50px; }
  .td.h30 {
    height: 30px;
    line-height: 30px; }
  .td.h35 {
    height: 35px;
    line-height: 35px; }
  .td.h40 {
    height: 40px;
    line-height: 40px; }
  .td.h80 {
    height: 80px;
    line-height: 80px; }
  .td.w170 {
    width: 170px; }
  .td.w160 {
    width: 160px; }
  .td.w150 {
    width: 150px; }
  .td.w120 {
    width: 120px; }
  .td.w100 {
    width: 100px; }
  .td.w460 {
    width: 455px; }
  .td.w90 {
    width: 90px; }
  .td.w80 {
    width: 80px; }
  .td.w140 {
    width: 140px; }
  .td.w70 {
    width: 70px; }
  .td.w360 {
    width: 360px; }
  .td .txt {
    padding: 0 0 0 12px;
    height: 100%;
    background: #fff; }
    .td .txt span {
      display: inline-block;
      vertical-align: middle;
      line-height: 1.3; }
    .td .txt.pink {
      background: #fcb4c0; }
    .td .txt.yellow {
      background: #ffff99; }
    .td .txt.gray {
      background: #e4e4e4; }
    .td .txt.gr {
      background: #e0fc9a; }
    .td .txt.kinako {
      background: #ffebb6; }
    .td .txt.blue {
      background: #bcd0fe; }
    .td .txt.upink {
      background: #f5e1f5; }
    .td .txt.murasaki {
      background: #CD9CDA; }
    .td .txt.oudo {
      background: #dde016; }
    .td .txt.kgr {
      background: #8be1b9; }
    .td .txt.hada {
      background: #ffc3a0; }
  .td a {
    color: #4e2901 !important;
    text-decoration: underline; }
  .td .bdr {
    position: absolute;
    top: 0;
    right: 0;
    width: 3px;
    height: 100%;
    padding: 3px 0 0;
    box-sizing: border-box; }
  .td .bdb {
    position: absolute;
    bottom: 0;
    height: 3px;
    width: 100%;
    padding: 0 0 0 3px;
    box-sizing: border-box; }
  .td .line {
    height: 100%;
    width: 100%;
    background: #c5926a; }

html {
  height: 100%; }

body {
  font-size: 14px;
  color: #4e2901;
  background: url(../img/bg.jpg) center top;
  background-size: 320px auto;
  min-height: 100%;
  font-family: sans-serif;
  line-height: 1.5; }

h1.logo {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1000; }

#header {
  position: relative;
  height: 50px;
  background: #fff;
  padding: 0 0 0 20px;
  width: 100%;
  box-sizing: border-box; }
  #header.fix {
    position: fixed;
    z-index: 100; }
  #header h1#logo {
    background: url(../img/sp/logo.png) left center no-repeat;
    background-size: 242px auto;
    height: 50px; }
  #header .menu-icon {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    padding: 17px 15px 0 0; }

#header-bg {
  position: relative;
  height: 120px;
  background: url(../img/header_bg.png) center center repeat-x; }

#menu {
  width: 100%;
  background: url(../img/common/menu_bg.png) repeat-x;
  height: 80px; }
  #menu .menu-dot {
    width: 100%;
    background: url(../img/common/menu_dot.png) center top no-repeat;
    height: 80px; }
  #menu #category {
    padding: 10px 0 0;
    height: 70px;
    float: left;
    width: 980px;
    margin: 0 5px; }
    #menu #category .li {
      position: relative;
      float: left;
      width: 182px;
      margin: 0 10px 0 0; }
    #menu #category .category {
      height: 59px;
      line-height: 59px;
      font-size: 0px;
      cursor: pointer; }
      #menu #category .category.home {
        background: url(../img/common/menu.png) 0 top no-repeat; }
      #menu #category .category.service {
        background: url(../img/common/menu.png) -192px top no-repeat; }
      #menu #category .category.company {
        background: url(../img/common/menu.png) -384px top no-repeat; }
      #menu #category .category.blog {
        background: url(../img/common/menu.png) -576px top no-repeat; }
      #menu #category .category.contact {
        background: url(../img/common/menu.png) -768px top no-repeat; }
      #menu #category .category:hover {
        background-position-y: -60px; }
      #menu #category .category.active {
        background-position-y: -60px !important; }
    #menu #category .subcategory {
      position: absolute;
      z-index: 10;
      top: 64px;
      left: -6px;
      background: #fff url(../img/common/saku_side.png);
      width: 195px;
      display: none; }
      #menu #category .subcategory span {
        display: none; }
      #menu #category .subcategory a {
        display: block;
        height: 40px; }
      #menu #category .subcategory .sul {
        padding: 0 9px 6px 9px;
        background: url(../img/common/saku_border.png) left bottom no-repeat; }
      #menu #category .subcategory .sli {
        width: 177px;
        height: 40px;
        line-height: 40px;
        margin: 0 0 3px;
        text-align: center; }
        #menu #category .subcategory .sli.s1 {
          background: url(../img/common/menu_service.png) left top no-repeat; }
        #menu #category .subcategory .sli.s2 {
          background: url(../img/common/menu_service.png) left -43px no-repeat; }
        #menu #category .subcategory .sli.s3 {
          background: url(../img/common/menu_service.png) left -86px no-repeat; }
        #menu #category .subcategory .sli.s4 {
          background: url(../img/common/menu_service.png) left -129px no-repeat; }
        #menu #category .subcategory .sli.s5 {
          background: url(../img/common/menu_service.png) left -172px no-repeat; }
        #menu #category .subcategory .sli.s6 {
          background: url(../img/common/menu_service.png) left -215px no-repeat; }

#footer {
  position: relative;
  background: #74472f;
  color: #f5ecd3;
  font-weight: bold;
  height: 250px; }
  #footer a {
    color: #f5ecd3; }
  #footer .nav {
    position: relative;
    padding: 25px 0 45px 40px;
    height: 160px;
    box-sizing: border-box; }
    #footer .nav .block {
      display: inline-block;
      vertical-align: top;
      width: 180px; }
      #footer .nav .block.li-blue li {
        background-image: url(../img/common/blue_arrow.png); }
      #footer .nav .block.li-pink li {
        background-image: url(../img/common/pink_arrow.png); }
      #footer .nav .block.li-yellow li {
        background-image: url(../img/common/yellow_arrow.png); }
      #footer .nav .block li {
        margin: 0 0 10px;
        padding: 0 0 0 12px;
        background-repeat: no-repeat;
        background-position: left center;
        background-image: url(../img/common/normal_arrow.png); }
        #footer .nav .block li.idn {
          margin: 0 0 10px 24px; }
  #footer .infomation {
    position: relative;
    padding: 0 0 0 390px; }
    #footer .infomation .logo {
      position: absolute;
      top: 0;
      left: 0; }
    #footer .infomation .address {
      line-height: 40px;
      height: 40px;
      font-size: 12px;
      /**/ }
    #footer .infomation .mark {
      position: absolute;
      right: 0;
      top: -20px; }
  #footer .copyright {
    position: absolute;
    font-size: 10px;
    font-family: Helvetica, sans-serif;
    bottom: 10px;
    width: 100%;
    text-align: center; }

#top .cnt .read {
  padding: 40px 0; }

#top .blocks {
  position: relative;
  width: 830px;
  margin: 0 auto; }
  #top .blocks .block {
    width: 250px;
    float: left;
    margin: 0 40px 0 0; }
    #top .blocks .block.last {
      margin: 0; }
    #top .blocks .block .sv {
      position: relative;
      padding: 0 0 25px;
      text-align: center; }
      #top .blocks .block .sv .scale {
        position: absolute;
        width: 100%;
        top: 0;
        text-align: center;
        z-index: 0; }
      #top .blocks .block .sv .ic {
        position: relative; }
    #top .blocks .block .desc {
      font-size: 13px; }

#service.posting .read {
  padding: 0; }
  #service.posting .read.cz {
    text-align: center;
    font-size: 18px; }
  #service.posting .read .em {
    font-weight: bold;
    text-decoration: underline; }

#service.posting h4.h4 {
  margin: 0 0 40px;
  font-size: 18px;
  color: #fff;
  padding: 0 0 0 45px;
  line-height: 40px;
  height: 40px;
  background: url(../img/common/shape-green.png) no-repeat; }

#service.posting .icons {
  padding: 30px 0 40px;
  text-align: center; }

#service.posting .areamap {
  padding: 0 0 0 30px; }

#service.posting .areas, #service.posting .price {
  padding: 0 0 0 50px; }

#service.posting .notice {
  padding: 20px 0 30px;
  font-size: 12px; }
  #service.posting .notice h5 {
    font-weight: bold;
    font-size: 14px; }
  #service.posting .notice.right {
    text-align: right; }
    #service.posting .notice.right a {
      color: #4e2901;
      text-decoration: underline; }

#service.posting .staff .staff-wrapper {
  position: relative;
  margin: 20px 0 0;
  padding: 0 0 30px 400px;
  height: 240px; }

#service.posting .staff .images {
  position: absolute;
  top: 0;
  left: 0; }

#service.posting .staff .icon02 {
  text-align: center;
  display: inline-block;
  margin: 0 30px 0 0; }

#service.posting .staff .icon03 {
  display: inline-block; }

#service.posting .punit {
  text-align: center; }
  #service.posting .punit p {
    display: inline-block; }
  #service.posting .punit .item {
    font-size: 34px; }
  #service.posting .punit .unit {
    font-size: 64px;
    font-weight: bold;
    margin: 0 30px; }
  #service.posting .punit .show a {
    color: #4e2901;
    text-decoration: underline; }

#service.posting .blocks {
  position: relative;
  overflow: hidden; }

#service.posting .area-wrapper {
  position: relative;
  overflow: hidden;
  width: 990px; }

#service.posting .block {
  float: left;
  width: 217px;
  margin: 0 30px 30px 0; }

#service.dm .desc {
  padding: 5px 0 0; }

#service.assembly .read {
  height: 160px;
  box-sizing: border-box; }

#service.assembly .icon {
  position: absolute;
  right: 60px;
  top: -15px; }

#service.assembly .cnt {
  width: 800px;
  margin: 0 auto 60px;
  padding: 0; }
  #service.assembly .cnt h4 {
    height: 50px;
    line-height: 50px; }
  #service.assembly .cnt .block {
    width: 380px;
    float: left;
    margin: 0 40px 0 0; }
    #service.assembly .cnt .block.last {
      margin: 0; }

#service.assembly .table {
  margin: 0 0 15px; }

#service.assembly .notice {
  padding: 15px 0 0; }
  #service.assembly .notice a {
    color: #ff0101;
    text-decoration: underline; }

#service.creative .icon {
  top: -15px; }

#service.creative .photo {
  padding: 10px 0;
  text-align: center; }

#service.event .read {
  padding-bottom: 50px; }

#service.event .icon {
  top: -15px;
  right: 60px; }

#service.event .cnt {
  width: 800px;
  margin: 0 auto 60px;
  padding: 0; }
  #service.event .cnt .block {
    width: 380px;
    float: left;
    margin: 0 40px 0 0; }
    #service.event .cnt .block h4 {
      height: 50px;
      line-height: 50px; }
    #service.event .cnt .block img {
      width: 380px; }
    #service.event .cnt .block.last {
      margin: 0; }

#service.event .notice {
  padding: 10px 0 0;
  text-align: right; }

#service.printing .read {
  padding-bottom: 30px; }

#service.printing .icon {
  top: -15px;
  right: 0; }

#service.printing .notice {
  padding: 10px 0 0;
  text-align: right; }

#service .cnt {
  position: relative;
  padding: 20px 0 0; }
  #service .cnt .blocks {
    position: relative;
    overflow: hidden; }
  #service .cnt .block {
    width: 300px;
    float: left;
    margin: 0 30px 0 0; }
    #service .cnt .block.last {
      margin: 0; }
  #service .cnt .desc {
    height: 140px;
    box-sizing: border-box; }

#service a {
  color: #009320; }

#service .read {
  position: relative;
  padding: 0 260px 0 0; }

#service .icon {
  position: absolute;
  right: 20px;
  top: 10px; }

#service h3.h3 {
  height: 40px;
  line-height: 40px;
  background: url(../img/service/shape.png) no-repeat;
  color: #fff;
  padding: 0 0 0 50px;
  margin: 0 0 30px; }

#service .price-wrapper {
  position: relative;
  padding: 20px 0 0; }
  #service .price-wrapper .notes {
    padding: 25px 0 60px;
    font-size: 12px; }

#service .security {
  padding: 0 0 30px; }
  #service .security .icon {
    position: absolute;
    top: 70px;
    left: 20px;
    padding: 0; }
  #service .security .desc {
    padding: 20px 0 0 260px; }

#contact.confirm .value {
  height: 40px;
  line-height: 40px; }
  #contact.confirm .value.multi {
    padding: 10px 0 0;
    height: auto;
    line-height: 1.8; }
  #contact.confirm .value .selected {
    padding: 0 0 20px; }

#contact.complete .read {
  padding-bottom: 40px; }

#contact .title {
  padding-top: 0; }

#contact .read {
  padding: 20px 0 0; }

#contact .banner {
  padding: 25px 0 50px; }

#contact .errors {
  padding: 20px 0;
  color: red;
  line-height: 1.5; }

#contact .require {
  width: 50px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  background: #e74f3e;
  font-size: 11px;
  display: inline-block;
  margin: 0 0 0 5px; }

#contact .agree {
  padding: 20px 0; }
  #contact .agree .ib {
    display: inline-block; }
  #contact .agree .checkbox {
    margin: 0 5px 30px 0; }
  #contact .agree #checkbox {
    border: 1px solid #9f6947;
    transform: scale(1.5);
    margin: 0 5px 0 0;
    vertical-align: middle; }
  #contact .agree .txt a {
    color: red;
    text-decoration: underline; }
  #contact .agree .button img {
    vertical-align: middle;
    margin: 0 8px 4px 0; }

#contact .search {
  display: inline-block;
  color: #fff;
  background-color: #9f6947;
  width: 80px;
  text-align: center;
  height: 36px;
  line-height: 36px;
  margin: 0 0 0 10px;
  cursor: pointer; }

#contact .submit {
  margin: 40px 0 10px; }

#contact .reset {
  cursor: pointer;
  text-align: center;
  text-decoration: underline;
  color: #9f6947; }

#contact .button {
  display: block;
  width: 380px;
  height: 60px;
  line-height: 60px;
  border-radius: 4px;
  margin: 0 auto;
  text-align: center;
  font-size: 21px;
  color: #fff;
  background-color: #9f6947;
  border: none; }

#contact .input {
  padding: 20px 0;
  overflow: hidden;
  border-bottom: 2px solid #d29a6b; }
  #contact .input.last {
    border: none; }

#contact .label {
  width: 250px;
  float: left;
  height: 40px;
  line-height: 40px; }

#contact .value {
  float: left;
  width: 610px; }

#contact .multi {
  margin: 0 0 20px; }

#contact .ips, #contact .input600, #contact .input480, #contact .input320, #contact .input120, #contact textarea {
  box-sizing: border-box;
  border: 2px solid #9f6947;
  border-radius: 4px;
  line-height: 40px;
  height: 40px;
  padding: 0 0 0 10px; }

#contact .input600 {
  width: 600px; }

#contact .input480 {
  width: 480px; }

#contact .input320 {
  width: 320px; }

#contact .input120 {
  width: 120px; }

#contact select {
  height: 50px;
  line-height: 50px;
  appearance: none;
  width: 300px;
  font-size: 18px;
  padding: 0 0 0 20px;
  box-sizing: border-box;
  background: #9f6947 url(../img/common/arrow.png) right center no-repeat;
  border-radius: 0;
  border: none;
  color: #fff; }

#contact textarea {
  width: 600px;
  height: 200px;
  line-height: 1.2;
  padding: 10px; }

#faq {
  position: relative;
  padding: 0 0 30px; }
  #faq .icon {
    padding: 0 0 20px; }
  #faq .block {
    margin: 0 0 30px; }
  #faq .label {
    width: 80px;
    float: left;
    padding: 0 0 0 10px;
    box-sizing: border-box; }
  #faq .txt {
    float: left;
    width: 880px;
    line-height: 24px; }
  #faq .ans {
    padding: 0 0 10px;
    overflow: hidden; }
    #faq .ans .txt {
      color: #4e2901;
      font-weight: bold;
      font-size: 21px;
      line-height: 36px;
      height: 36px; }
  #faq .que {
    padding: 20px 0 0;
    overflow: hidden; }

#flow .block {
  position: relative;
  overflow: hidden; }

#flow .flow-wrapper {
  box-sizing: border-box;
  padding: 0 20px; }

#flow .label {
  float: left;
  width: 300px; }

#flow .txt {
  float: left;
  width: 620px;
  display: table;
  height: 80px; }

#flow a {
  color: red;
  text-decoration: underline; }

#flow .ac {
  display: table-cell;
  vertical-align: middle; }

#flow .arrow {
  width: 280px;
  text-align: center;
  padding: 20px 0 20px; }

#flow .notice {
  padding: 40px 0 0; }

#sitemap .sitemap-wrapper {
  padding: 0 20px;
  overflow: hidden; }
  #sitemap .sitemap-wrapper .block {
    margin: 0 0 15px; }
  #sitemap .sitemap-wrapper .left {
    float: left;
    width: 460px;
    padding: 0 0 0 40px;
    box-sizing: border-box; }
  #sitemap .sitemap-wrapper .right {
    float: left;
    width: 460px;
    padding: 0 0 0 20px;
    box-sizing: border-box; }
  #sitemap .sitemap-wrapper .ul {
    padding: 15px 0 0 18px;
    list-style: disc;
    overflow: hidden;
    margin: 0 0 30px; }
    #sitemap .sitemap-wrapper .ul.small {
      margin: 0; }
      #sitemap .sitemap-wrapper .ul.small li {
        width: 200px;
        float: left; }
    #sitemap .sitemap-wrapper .ul li {
      margin: 0 0 8px; }
      #sitemap .sitemap-wrapper .ul li.idn {
        margin: 0 0 8px 24px; }
    #sitemap .sitemap-wrapper .ul a {
      text-decoration: underline;
      color: #50320a;
      font-weight: bold; }
  #sitemap .sitemap-wrapper .box {
    display: block;
    height: 60px;
    line-height: 54px;
    background: #fff;
    color: #9f6947;
    padding: 0 0 0 20px;
    width: 400px;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 18px;
    font-weight: bold;
    border: 3px solid #9f6947; }

#company .ceo {
  text-align: right;
  padding: 20px 0 0; }

#company .line {
  margin: 0 0 20px; }

#company .left {
  float: left;
  width: 120px; }

#company .right {
  float: left;
  width: 840px; }

#company .block {
  overflow: hidden;
  margin: 0 0 15px;
  line-height: 1.5; }

#company .can {
  display: inline-block;
  width: 180px; }

#company a {
  color: #4e2901;
  text-decoration: underline; }

#company #outline {
  position: relative;
  padding: 0 0 60px; }
  #company #outline .outline-wrapper {
    width: 480px; }
  #company #outline .right {
    width: 360px; }
  #company #outline .graph {
    position: absolute;
    right: 0;
    top: -18px; }

#company #desc {
  position: relative;
  padding: 0 0 60px; }

#company .logos {
  padding: 20px 0 0; }
  #company .logos p {
    display: inline-block; }
  #company .logos .org {
    margin: 0 0 0 30px; }

#company .map {
  padding: 10px;
  background: #fff; }

#company #map {
  width: 940px;
  height: 380px; }

#blog {
  position: relative; }
  #blog a {
    color: #ff0001;
    text-decoration: underline; }
  #blog .date {
    font-size: 12px; }
  #blog .title {
    padding: 5px 0; }
  #blog .cnt {
    width: 680px;
    min-height: 400px; }
  #blog .bot {
    margin: 0 0 20px; }
  #blog .top {
    margin: 20px 0 0; }
  #blog .body {
    line-height: 1.8; }
  #blog .block {
    margin: 0 0 30px; }
  #blog .paging {
    padding: 20px 0 0;
    text-align: center; }
    #blog .paging .ib {
      display: inline-block; }
    #blog .paging a {
      color: #4e2901; }
  #blog .blog-nav {
    position: absolute;
    right: 0;
    top: 0;
    width: 238px; }
    #blog .blog-nav .box {
      margin: 0 0 30px; }
    #blog .blog-nav .li {
      margin: 0 0 5px; }
    #blog .blog-nav a {
      color: #9f6947;
      text-decoration: underline; }
  #blog .ob {
    height: 35px;
    line-height: 35px;
    padding: 0 0 0 10px;
    background: #9f6947;
    color: #fff;
    margin: 0 0 20px; }
  #blog pre {
    word-wrap: break-word; }

#privacy .read {
  padding: 30px 0 0; }

#privacy .info {
  text-align: right; }

#privacy .pg {
  padding: 0 0 30px;
  line-height: 1.8; }

#privacy .closure {
  padding: 10px 0 20px;
  text-align: center; }

#privacy h4 {
  font-weight: bold; }

#privacy a {
  color: red;
  text-decoration: underline; }

#career .icons {
  padding: 40px 0;
  text-align: center; }

#career h3 {
  height: 40px;
  line-height: 40px;
  color: #fff;
  background: url(../img/common/circle-line.png) no-repeat;
  font-weight: bold;
  box-sizing: border-box;
  padding: 0 0 0 50px;
  margin: 0 0 5px; }

#career .wrap {
  overflow: hidden;
  width: 960px; }

#career .job {
  float: left;
  width: 460px;
  padding: 0 20px 0 0;
  margin: 0 0 40px; }

#career .sub {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: bold; }

#career .desc {
  padding: 8px 0;
  height: 80px; }

#career .noc {
  border: 2px solid #d29a6b;
  text-align: center;
  height: 50px;
  line-height: 50px; }

body, figure {
  margin: 0;
  padding: 0; }

#animation {
  position: relative;
  width: 100%;
  height: 541px;
  overflow: hidden; }

#keycontent .parts {
  position: relative;
  height: 541px; }

#keycontent.night .fuji {
  background: url(../img/top/anime_night/back_fuji.png) repeat-x center bottom; }

#keycontent.night .bill {
  background: url(../img/top/anime_night/back_bill.png) repeat-x center bottom; }

#keycontent.night .camp {
  background: url(../img/top/anime_night/parts_camp.png) repeat-x center bottom; }

#keycontent.night .house {
  background: url(../img/top/anime_night/parts_house.png) repeat-x center bottom; }

#keycontent.night .rail {
  background: url(../img/top/anime_night/back_rail.png) repeat-x center bottom; }

#keycontent.night .kikyu {
  background: url(../img/top/anime_night/parts_kikyu.png) no-repeat; }

#keycontent.night .track1 {
  background: transparent url(../img/top/anime_night/parts_track1.png) no-repeat top left; }

#keycontent.night .car1 {
  background: transparent url(../img/top/anime_night/parts_car.png) no-repeat top left; }

#keycontent.night .bike1 {
  background: transparent url(../img/top/anime_night/parts_bike1.png) no-repeat top left; }

#keycontent.night .bike2 {
  background: transparent url(../img/top/anime_night/parts_bike2.png) no-repeat top left; }

#keycontent.night .bird1 {
  background: transparent url(../img/top/anime_night/parts_bird.png) no-repeat top left; }

#keycontent.night .bird2 {
  background: transparent url(../img/top/anime_night/parts_bird2.png) no-repeat top left; }

#keycontent.night .bicycle1 {
  background: transparent url(../img/top/anime_night/parts_bicycle1.png) no-repeat top left; }

#keycontent.night .bicycle2 {
  background: transparent url(../img/top/anime_night/parts_bicycle2.png) no-repeat top left; }

#keycontent.night .man1 {
  background: transparent url(../img/top/anime_night/parts_man1.png) no-repeat top left; }

#keycontent.night .man2 {
  background: transparent url(../img/top/anime_night/parts_man2.png) no-repeat top left; }

#keycontent.night .comp {
  background: url(../img/top/anime_night/parts_company.png) no-repeat; }

#keycontent.night .kj {
  background: url(../img/top/anime_night/parts_kj.png) no-repeat; }

#keycontent .pop {
  position: absolute;
  top: -10px; }
  #keycontent .pop.huki1 {
    width: 96px;
    height: 121px;
    background-image: url(../img/top/anime/huki_pos.png); }
  #keycontent .pop.huki2 {
    left: 40px;
    width: 96px;
    height: 121px;
    background-image: url(../img/top/anime/huki_event.png); }
  #keycontent .pop.huki3 {
    width: 97px;
    height: 112px;
    background-image: url(../img/top/anime/huki_company.png); }
  #keycontent .pop.huki4 {
    width: 96px;
    height: 121px;
    background-image: url(../img/top/anime/huki_blog.png); }
  #keycontent .pop.huki5 {
    width: 97px;
    height: 113px;
    background-image: url(../img/top/anime/huki_cre.png); }
  #keycontent .pop.huki6 {
    width: 97px;
    height: 121px;
    background-image: url(../img/top/anime/huki_direct.png); }
  #keycontent .pop.huki7 {
    width: 97px;
    height: 112px;
    background-image: url(../img/top/anime/huki_ass.png);
    top: -40px; }
  #keycontent .pop:hover {
    background-position-y: bottom; }

#keycontent .fuji {
  background: url(../img/top/anime/back_fuji2.png) repeat-x center bottom;
  width: 100%;
  height: 428px; }

#keycontent .bill {
  background: url(../img/top/anime/back_bill.png) repeat-x center bottom;
  width: 100%;
  height: 428px;
  z-index: 1000;
  position: absolute; }

#keycontent .camp {
  background: url(../img/top/anime/parts_camp.png) repeat-x center bottom;
  width: 100%;
  height: 541px;
  z-index: 100;
  position: absolute; }

#keycontent .house {
  background: url(../img/top/anime/parts_house.png) repeat-x center bottom;
  width: 100%;
  height: 126px;
  z-index: 110;
  top: 300px;
  position: absolute; }

#keycontent .rail {
  background: url(../img/top/anime/back_rail.png) repeat-x center bottom;
  width: 100%;
  top: 437px;
  height: 23px;
  z-index: 1100;
  position: absolute; }

#keycontent .shinkansen {
  position: absolute;
  top: 342px;
  left: -508px;
  z-index: 10; }

#keycontent .cloud {
  position: absolute;
  z-index: 10; }

#keycontent .cloud1 {
  top: 30px;
  left: -10px; }

#keycontent .cloud2 {
  top: 200px;
  left: 50px; }

#keycontent .cloud3 {
  top: 20px;
  left: 30%; }

#keycontent .cloud4 {
  top: 80px;
  left: 60%; }

#keycontent .cloud5 {
  top: 220px;
  left: 65%; }

#keycontent .cloud6 {
  top: 100px;
  left: 85%; }

#keycontent .cloud7 {
  top: 250px;
  left: 95%; }

#keycontent .cloud8 {
  top: 0px;
  left: 15%; }

#keycontent .cloud9 {
  top: 80px;
  left: 72%; }

#keycontent .cloud10 {
  top: 30px;
  left: 45%; }

#keycontent .plane {
  position: absolute;
  z-index: 110;
  left: -243px;
  top: 100px; }

#keycontent .kikyu {
  position: absolute;
  background: url(../img/top/anime/parts_kikyu.png) no-repeat;
  z-index: 150;
  width: 43px;
  height: 158px;
  left: 100%;
  top: 50px; }

#keycontent .huki5 {
  position: absolute;
  z-index: 1060;
  margin-left: 20px; }

#keycontent .track1 {
  position: absolute;
  z-index: 1170;
  top: 280px;
  left: -200px;
  width: 200px;
  height: 193px;
  display: block;
  background: transparent url(../img/top/anime/parts_track1.png) no-repeat top left; }

#keycontent .huki2 {
  position: absolute;
  z-index: 1060;
  margin-left: 20px; }

#keycontent .car1 {
  position: absolute;
  z-index: 1171;
  top: 435px;
  left: 100%;
  width: 160px;
  height: 70px;
  display: block;
  background: transparent url(../img/top/anime/parts_car.png) no-repeat top left; }

#keycontent .bike1 {
  position: absolute;
  z-index: 1160;
  top: 370px;
  left: -165px;
  width: 165px;
  height: 100px;
  display: block;
  overflow: hidden;
  background: transparent url(../img/top/anime/parts_bike1.png) no-repeat top left; }

#keycontent .bike2 {
  position: absolute;
  z-index: 1180;
  top: 297px;
  left: 100%;
  width: 275px;
  height: 208px;
  display: block;
  overflow: hidden;
  background: transparent url(../img/top/anime/parts_bike2.png) no-repeat top left; }

#keycontent .bird1 {
  position: absolute;
  z-index: 1300;
  top: 220px;
  left: -158px;
  width: 81px;
  height: 43px;
  display: block;
  overflow: hidden;
  background: transparent url(../img/top/anime/parts_bird.png) no-repeat top left; }

#keycontent .bird2 {
  position: absolute;
  z-index: 1250;
  top: 220px;
  left: 100%;
  width: 70px;
  height: 52px;
  display: block;
  overflow: hidden;
  background: transparent url(../img/top/anime/parts_bird2.png) no-repeat top left; }

#keycontent .bicycle1 {
  position: absolute;
  z-index: 1080;
  top: 230px;
  left: -100px;
  width: 100px;
  height: 210px;
  display: block;
  background: transparent url(../img/top/anime/parts_bicycle1.png) no-repeat top left; }

#keycontent .huki1 {
  position: absolute;
  z-index: 1081; }

#keycontent .bicycle2 {
  position: absolute;
  z-index: 1085;
  top: 237px;
  left: 100%;
  width: 112px;
  height: 214px;
  display: block;
  background: transparent url(../img/top/anime/parts_bicycle2.png) no-repeat top left; }

#keycontent .huki6 {
  position: absolute;
  z-index: 1086; }

#keycontent .man1 {
  position: absolute;
  z-index: 1095;
  top: 375px;
  left: 100%;
  width: 70px;
  height: 80px;
  display: block;
  overflow: hidden;
  background: transparent url(../img/top/anime/parts_man1.png) no-repeat top left; }

#keycontent .man2 {
  position: absolute;
  left: 40%;
  z-index: 1070;
  top: 245px;
  width: 78px;
  height: 180px;
  display: block;
  background: transparent url(../img/top/anime/parts_man2.png) no-repeat top left; }

#keycontent .post_o {
  position: absolute;
  z-index: 1065;
  left: 60%;
  margin-left: 50px;
  top: 360px; }

#keycontent .post_p {
  position: absolute;
  z-index: 1065;
  left: 20%;
  margin-left: 50px;
  top: 360px; }

#keycontent .post_r {
  position: absolute;
  z-index: 1065;
  left: 40%;
  margin-left: 50px;
  top: 360px; }

#keycontent .comp {
  position: absolute;
  background: url(../img/top/anime/parts_company.png) no-repeat;
  height: 182px;
  width: 130px;
  z-index: 1050;
  left: 25%;
  top: 235px; }

#keycontent .kj {
  position: absolute;
  background: url(../img/top/anime/parts_kj.png) no-repeat;
  width: 234px;
  z-index: 1050;
  left: 68%;
  top: 275px;
  height: 145px; }

#lp #header-bg {
  position: relative;
  background-image: none;
  position: absolute;
  width: 100%;
  z-index: 100; }

#lp .header-sub {
  position: relative;
  background: #75b000;
  color: #fff;
  height: 40px;
  line-height: 40px;
  font-weight: bold;
  width: 100%;
  overflow: hidden;
  text-align: center; }

#lp #main {
  position: relative;
  text-align: center;
  box-sizing: border-box; }
  #lp #main .logo {
    position: absolute;
    width: 100%;
    top: 10px; }
  #lp #main .read {
    position: absolute;
    width: 100%;
    top: 100px;
    z-index: 1000; }
  #lp #main .subread {
    position: absolute;
    top: 230px;
    width: 100%;
    text-align: center;
    z-index: 1000; }
  #lp #main #animation {
    height: 770px; }
  #lp #main #keycontent.night .fuji {
    background: url(../img/lp/anime_night/back_fuji.png) repeat-x center bottom; }
  #lp #main #keycontent .fuji {
    height: 657px;
    padding: 229px 0 0;
    box-sizing: border-box;
    background: url(../img/lp/anime/back_fuji.png) repeat-x center bottom; }
  #lp #main #keycontent .pop {
    display: none; }

#lp #posting {
  position: relative;
  padding: 40px 0 60px; }
  #lp #posting .title {
    padding: 0 0 30px; }
  #lp #posting .images {
    overflow: hidden;
    width: 750px;
    margin: 0 auto; }
    #lp #posting .images .left {
      float: left; }
    #lp #posting .images .right {
      float: right; }
  #lp #posting .list {
    position: relative;
    padding: 40px 0 0; }
    #lp #posting .list .block {
      font-size: 24px;
      overflow: hidden;
      margin: 0 0 10px;
      color: #86471d;
      font-weight: bold; }
      #lp #posting .list .block .left {
        float: left;
        width: 50px; }
      #lp #posting .list .block .right {
        float: left; }
    #lp #posting .list .notes {
      padding: 20px 0 0;
      color: #6e5531;
      line-height: 22px; }
  #lp #posting #map .title {
    padding: 30px 0 0; }
  #lp #posting #map .map {
    position: relative;
    top: -20px; }
  #lp #posting #map .desc {
    line-height: 22px; }

#lp #case {
  position: relative;
  padding: 60px 0 0; }
  #lp #case .title {
    padding: 0 0 35px; }
  #lp #case .map-wrapper {
    position: relative;
    width: 750px;
    margin: 0 auto;
    overflow: hidden;
    padding: 0 0 70px; }
    #lp #case .map-wrapper .left {
      float: left;
      width: 360px; }
    #lp #case .map-wrapper .right {
      float: right;
      width: 360px; }
    #lp #case .map-wrapper .icon {
      position: absolute;
      width: 100%;
      padding: 0;
      z-index: 10;
      text-align: center;
      bottom: 0; }
  #lp #case .desc {
    position: relative;
    padding: 10px 0 30px;
    overflow: hidden; }
    #lp #case .desc .block {
      margin: 0 30px 30px 0;
      float: left;
      width: 300px; }
      #lp #case .desc .block.last {
        margin-right: 0; }
    #lp #case .desc .case {
      background: #009de7;
      padding: 0 0 0 10px;
      font-size: 16px;
      margin: 0 0 20px; }
  #lp #case .read {
    padding: 0 0 20px; }
  #lp #case .example {
    position: relative;
    overflow: hidden; }
    #lp #case .example .block {
      border: 1px solid #d19a6b;
      padding: 20px 27px;
      background: #fff;
      margin: 0 40px 40px 0;
      width: 460px;
      height: 260px;
      box-sizing: border-box;
      float: left; }
      #lp #case .example .block.c2 {
        margin-right: 0; }
    #lp #case .example .case {
      background: #d40077;
      text-align: center; }
    #lp #case .example .que {
      height: 36px;
      line-height: 36px;
      padding: 0; }
    #lp #case .example .ans {
      line-height: 22px; }
  #lp #case .case {
    height: 30px;
    line-height: 30px;
    color: #fff;
    margin: 0 0 5px 0;
    font-weight: bold; }
  #lp #case .que {
    font-weight: bold;
    color: #4e2901;
    padding: 0 0 5px;
    font-size: 16px;
    line-height: 24px; }
  #lp #case .ans {
    padding: 15px 0 0;
    line-height: 24px; }

#lp #qa {
  position: relative;
  padding: 0 0 30px; }
  #lp #qa .icon {
    padding: 0 0 20px; }
  #lp #qa .block {
    margin: 0 0 30px; }
  #lp #qa .label {
    width: 80px;
    float: left;
    padding: 0 0 0 10px;
    box-sizing: border-box; }
  #lp #qa .txt {
    float: left;
    width: 880px;
    line-height: 24px; }
  #lp #qa .ans {
    padding: 0 0 10px;
    overflow: hidden; }
    #lp #qa .ans .txt {
      color: #4e2901;
      font-weight: bold;
      font-size: 21px;
      line-height: 36px;
      height: 36px; }
  #lp #qa .que {
    padding: 20px 0 0;
    overflow: hidden; }

#lp .contact-icon {
  position: absolute;
  width: 100%;
  z-index: 10;
  bottom: -21px;
  text-align: center; }

#lp .services {
  position: relative;
  background: #fff;
  padding: 35px 0 0;
  height: 280px;
  box-sizing: border-box; }
  #lp .services .service-wrapper {
    position: relative;
    overflow: hidden; }
  #lp .services .s {
    height: 60px;
    line-height: 60px;
    text-align: center;
    color: #fff;
    width: 320px;
    float: left;
    font-weight: bold; }
    #lp .services .s.first {
      background: #009ee7; }
    #lp .services .s.second {
      background: #d40077; }
    #lp .services .s.third {
      background: #ffcc00;
      color: #86471d; }
  #lp .services .contact-wrapper {
    position: relative;
    background: #fff;
    padding: 155px 0 0; }
    #lp .services .contact-wrapper .tel, #lp .services .contact-wrapper .mail {
      position: absolute;
      top: 20px;
      z-index: 11; }
    #lp .services .contact-wrapper .tel {
      position: absolute;
      left: 0; }
    #lp .services .contact-wrapper .mail {
      right: 0; }
    #lp .services .contact-wrapper .icon {
      position: absolute;
      width: 100%;
      z-index: 10;
      text-align: center;
      top: -10px; }
    #lp .services .contact-wrapper .info {
      color: #000;
      padding: 4px 0 0;
      text-align: center;
      line-height: 12px;
      font-size: 12px; }

#lp #footer {
  height: 70px; }
  #lp #footer .logo {
    padding: 15px 0 0;
    position: absolute;
    z-index: 10; }
  #lp #footer .copyright {
    position: relative;
    top: 0;
    right: 0;
    text-align: right;
    line-height: 70px;
    height: 70px; }
