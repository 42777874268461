@import "reset";

// common

$color: #4e2901;
$green:#009320;

.cf:before,
.cf:after {
    content: " ";
    display: table;
}

.cf:after {
    clear: both;
}

.cf {
    *zoom: 1;
}

* {
  outline: none;
}


img {
  vertical-align:  top;
  border: none;
}

a {
  text-decoration: none;
  &.opa {
    &:hover {
      opacity: 0.7;
    }
  }
}

.crumb {
  background: #9f6947;
  color: #ffffff;
  font-weight: bold;
  line-height: 35px;
  height: 35px;
  a {
    color: #fff !important;
    &:hover {
      text-decoration: underline;
    }
  }
}

.center {
  text-align: center;
}

.dot {
  height: 2px;
  background: url(../img/sp/common/dot.png) center top;
  background-size: auto 2px;
}

.slick-dots {
  position: relative;
  text-align: center;
  li {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #dec5b0;
    border-radius: 5px;

    margin: 0 4px 0;
    &.slick-active {
      background-color: $green;
    }
    button {
      opacity: 0;
    }
  }
}

.contact {
  position: relative;
  &.sub {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1000;
  }
  a {
    display: block;
    height: 100%;
    span {
      font-size: 0;
    }
  }
  .wrap {
    overflow: hidden;
    width: 375px;
  }
  .tel {
    float: left;
  }
  .mail {
    float: left;
  }
}

.green {
  color: #009320;
}

.wrapper {
  position: relative;
  width: 960px;
  margin: 0 auto;
}

.page-title {
  position: relative;
  padding: 40px 0;
  text-align: center;
}

p.pg {
  padding: 0 0 25px;
}

h3.title {
  position: relative;
  padding: 15px 0 5px;
  font-size: 20px;
  font-weight: bold;
}

.ads {
  padding: 60px 0 80px;
  text-align: center;
  overflow: hidden;
  .ad {
    width: 300px;
    float: left;
    margin: 0 30px 0 0;
    &.last {
      margin: 0;
    }
    img {
      width: 100%;
    }
    .note {
      padding: 20px 0 0;
      text-align: center;
    }
  }
}

.slider {
  position: relative;

  .arrow-left {
    position: absolute;
    left: 0;
    top: 62px;
    z-index: 10;
    cursor: pointer;
  }
  .arrow-right {
    position: absolute;
    right: 0;
    top: 62px;
    z-index: 10;
    cursor: pointer;
  }


  .sv {
    width: 240px;
    margin: 0 auto;
  }

  .desc {
    padding: 10px 0 0;
    height: 100px;
    line-height: 1.5;
    box-sizing: border-box;
  }

}

.tr {
  border-collapse: separate;  /* ←ココで余白 */
  border-spacing: 1px;  /* ←ココで余白 */
  &.center {
    .txt {
      padding: 0;
      text-align: center;
    }
  }
}

.head {
  .td {
    .txt {
      text-align: center;
      padding: 0;
    }
  }
}
.total {
  padding:10px 0 20px;
}
.td {
  position: relative;
  padding: 0 3px 3px 0;
  display: table-cell;
  height: 30px;
  font-size: 12px;
  //border-right: 1px solid rgba()
  //margin: 0 1px 0 0;
  vertical-align: middle;
  line-height: 30px;
  &.h50 {
    height: 50px;
    line-height: 50px;
  }
  &.h30 {
    height: 30px;
    line-height: 30px;
  }
  &.h35 {
    height: 35px;
    line-height: 35px;
  }
  &.h40 {
    height: 40px;
    line-height: 40px;
  }
  &.h80 {
    height: 80px;
    line-height: 80px;
  }

  &.w170 {
    width: 170px;
  }
  &.w160 {
    width: 160px;
  }
  &.w150 {
    width: 150px;
  }
  &.w120 {
    width: 120px;
  }
  &.w100 {
    width: 100px;
  }
  &.w460 {
    width: 455px;
  }
  &.w90 {
    width: 90px;
  }
  &.w80 {
    width: 80px;
  }
  &.w140 {
    width: 140px;
  }
  &.w70 {
    width: 70px;
  }
  &.w360 {
    width: 360px;
  }
  &.h100p {

  }

  .txt {
    padding: 0 0 0 12px;
    height: 100%;
    background: #fff;
    span {
      display: inline-block;
      vertical-align: middle;
      line-height: 1.3;
    }
    &.pink {
      background: #fcb4c0;
    }
    &.yellow {
      background: #ffff99;
    }
    &.gray {
      background: #e4e4e4;
    }
    &.gr {
      background: #e0fc9a;
    }
    &.kinako {
      background: #ffebb6;
    }

    &.blue {
      background: #bcd0fe;
    }
    &.upink {
      background: #f5e1f5;
    }
    &.murasaki {
      background: #CD9CDA;
    }
    &.oudo {
      background: #dde016;
    }
    &.kgr {
      background: #8be1b9;
    }
    &.hada {
      background: #ffc3a0;
    }
  }


  a {
    color: #4e2901 !important;
    text-decoration: underline;
  }

  .bdr {
    position: absolute;
    top: 0;
    right: 0;
    width: 3px;
    height: 100%;
    padding: 3px 0 0;
    box-sizing: border-box;
  }
  .bdb {
    position: absolute;
    bottom: 0;
    height: 3px;
    width: 100%;
    padding: 0 0 0 3px;
    box-sizing: border-box;
  }
  .line {
    height: 100%;
    width: 100%;
    background: #c5926a;
  }
}


html {
  height: 100%;
}

body {
  font-size: 14px;
  color: $color;
  background: url(../img/bg.jpg) center top;
  background-size: 320px auto;
  min-height: 100%;
  font-family: sans-serif;
  line-height: 1.5;
}

h1.logo {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1000;
  //padding: 20px 0 0 20px;
}


#header {
  position: relative;
  height: 50px;
  background: #fff;
  padding: 0 0 0 20px;
  width: 100%;
  box-sizing: border-box;
  &.fix {
    position: fixed;
    z-index: 100;
  }
  h1#logo {
    background: url(../img/sp/logo.png) left center no-repeat;
    background-size: 242px auto;
    height: 50px;
  }

  .menu-icon {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    padding: 17px 15px 0 0;
  }

}

#header-bg {
  position: relative;
  height: 120px;
  background: url(../img/header_bg.png) center center repeat-x;

}

#menu {
  width: 100%;
  background: url(../img/common/menu_bg.png) repeat-x;
  height: 80px;
  .menu-dot {
    width: 100%;
    background: url(../img/common/menu_dot.png) center top no-repeat;
    height: 80px;
  }
  #category {
    padding: 10px 0 0;
    height: 70px;
    float: left;
    width: 980px;
    margin: 0 5px;
    .li {
      position: relative;
      float: left;
      width: 182px;
      margin: 0 10px 0 0;
    }
    .category {
      height: 59px;
      line-height: 59px;
      font-size: 0px;
      cursor: pointer;
      &.home {
        background: url(../img/common/menu.png) 0 top no-repeat;
      }
      &.service {
        background: url(../img/common/menu.png) -192px top no-repeat;
      }

      &.company {
        background: url(../img/common/menu.png) -384px top no-repeat;
      }

      &.blog {
        background: url(../img/common/menu.png) -576px top no-repeat;
      }

      &.contact {
        background: url(../img/common/menu.png) -768px top no-repeat;
      }

      &:hover {
        background-position-y: -60px;
      }
      &.active {
        background-position-y: -60px !important;
      }
    }
    .subcategory {
      position: absolute;
      z-index: 10;
      top: 64px;
      left: -6px;
      background: #fff url(../img/common/saku_side.png);
      width: 195px;
      display: none;

      span {
        display: none;
      }
      a {
        display: block;
        height: 40px;
      }
      .sul {
        padding: 0 9px 6px 9px;
        background: url(../img/common/saku_border.png) left bottom no-repeat;
      }
      .sli {
        width: 177px;
        height: 40px;
        line-height: 40px;
        margin: 0 0 3px;
        text-align: center;
        &.s1 {
          background: url(../img/common/menu_service.png) left top no-repeat;
        }
        &.s2 {
          background: url(../img/common/menu_service.png) left -43px no-repeat;
        }
        &.s3 {
          background: url(../img/common/menu_service.png) left -86px no-repeat;
        }
        &.s4 {
          background: url(../img/common/menu_service.png) left -129px no-repeat;
        }
        &.s5 {
          background: url(../img/common/menu_service.png) left -172px no-repeat;
        }
        &.s6 {
          background: url(../img/common/menu_service.png) left -215px no-repeat;
        }
      }
    }
  }
}

#footer {
  position: relative;
  background: #74472f;
  color: #f5ecd3;
  font-weight: bold;
  height: 250px;
  a {
    color: #f5ecd3;
  }

  .nav {
    position: relative;
    padding: 25px 0 45px 40px;
    height: 160px;
    box-sizing: border-box;

    .block {
      display: inline-block;
      vertical-align: top;
      width: 180px;

      &.li-blue {
        li {
          background-image: url(../img/common/blue_arrow.png);
        }
      }

      &.li-pink {
        li {
          background-image: url(../img/common/pink_arrow.png);
        }
      }

      &.li-yellow {
        li {
          background-image: url(../img/common/yellow_arrow.png);
        }
      }

      li {
        margin: 0 0 10px;
        padding: 0 0 0 12px;
        background-repeat: no-repeat;
        background-position: left center;
        background-image: url(../img/common/normal_arrow.png);
        &.idn {
          margin: 0 0 10px 24px;
        }
      }
    }
  }

  .infomation {
    position: relative;
    padding: 0 0 0 390px;

    .logo {
      position: absolute;
      top: 0;
      left: 0;
    }
    .address {
      line-height: 40px;
      height: 40px;
      font-size: 12px;
    /**/}
    .mark {
      position: absolute;
      right: 0;
      top: -20px;
    }
  }

  .copyright {
    position: absolute;
    font-size: 10px;
    font-family: Helvetica, sans-serif;
    bottom: 10px;
    width: 100%;
    text-align: center;
  }

}

#top {

  .cnt {
    .read {
      padding: 40px 0;
    }
  }

  .blocks {
    position: relative;
    // overflow: hidden;
    width: 830px;
    margin: 0 auto;

    .block {
      width: 250px;
      float: left;
      margin: 0 40px 0 0;
      &.last {
        margin: 0;
      }
      .sv {
        position: relative;
        padding: 0 0 25px;
        text-align: center;
        .scale {
          position: absolute;
          width: 100%;
          top: 0;
          text-align: center;
          z-index: 0;
        }
        .ic {
          position: relative;
        }
      }
      .desc {
        font-size: 13px;
      }
    }
  }
}


#service {

  &.posting {
    .read {
      padding: 0;
      &.cz {
        text-align: center;
        font-size: 18px;
      }
      .em {
        font-weight: bold;
        text-decoration: underline;
      }
    }
    h4.h4 {
      margin: 0 0 40px;
      font-size: 18px;
      color: #fff;
      padding: 0 0 0 45px;
      line-height: 40px;
      height: 40px;
      background: url(../img/common/shape-green.png) no-repeat;
    }
    .icons {
      padding: 30px 0 40px;
      text-align: center;
    }
    .areamap {
      padding: 0 0 0 30px;
    }
    .areas, .price {
      padding: 0 0 0 50px;
    }
    .notice {
      padding: 20px 0 30px;
      font-size: 12px;
      h5 {
        font-weight: bold;
        font-size: 14px;
      }
      &.right {
        text-align: right;
        a {
          color: #4e2901;
          text-decoration: underline;
        }
      }
    }
    .staff {
      .staff-wrapper {
        position: relative;
        margin: 20px 0 0;
        padding: 0 0 30px 400px;
        height: 240px;
      }
      .images {
        position: absolute;
        top: 0;
        left: 0;
      }
      .icon02 {
        text-align: center;
        display: inline-block;
        margin: 0 30px 0 0;
      }
      .icon03 {
        display: inline-block;
      }
    }
    .punit {
      text-align: center;
      p {
        display: inline-block;
      }
      .item {
        font-size: 34px;
      }
      .unit {
        font-size: 64px;
        font-weight: bold;
        margin: 0 30px;
      }
      .show {
        a {
          color: #4e2901;
          text-decoration: underline;
        }
      }
    }
    .blocks {
      position: relative;
      overflow: hidden;
    }
    .area-wrapper {
      position: relative;
      overflow: hidden;
      width: 990px;
    }
    .block {
      float: left;
      width: 217px;
      margin:0 30px 30px 0;
    }
  }

  &.dm {

    .desc {
      padding: 5px 0 0;
    }
  }

  &.assembly {
    .read {
      height: 160px;
      box-sizing: border-box;
    }
    .icon {
      position: absolute;
      right: 60px;
      top: -15px;
    }
    .cnt {
      width: 800px;
      margin: 0 auto 60px;
      padding: 0;
      h4 {
        height: 50px;
        line-height: 50px;
      }
      .block {
        width: 380px;
        float: left;
        margin: 0 40px 0 0;
        &.last {
          margin: 0;
        }
      }
    }
    .table {
      margin: 0 0 15px;
    }
    .notice {
      padding: 15px 0 0;
      a {
        color: #ff0101;
        text-decoration: underline;
      }
    }
  }

  &.creative {
    .icon {
      top: -15px;
    }
    .photo {
      padding: 10px 0;
      text-align: center;
    }
  }

  &.event {
    .read {
      padding-bottom: 50px;
    }
    .icon {
      top: -15px;
      right: 60px;
    }
    .cnt {
      width: 800px;
      margin: 0 auto 60px;
      padding: 0;
      .block {
        width: 380px;
        float: left;
        margin: 0 40px 0 0;
        h4 {
          height: 50px;
          line-height: 50px;
        }
        img {
          width: 380px;
        }

        &.last {
          margin: 0;
        }
      }
    }
    .notice {
      padding: 10px 0 0;
      text-align: right;
    }
  }

  &.printing {
    .read {
      padding-bottom: 30px;
    }
    .icon {
      top: -15px;
      right: 0;
    }
    .notice {
      padding: 10px 0 0;
      text-align: right;
    }
  }

  .cnt {
    position: relative;
    padding: 20px 0 0;
    .blocks {
      position: relative;
      overflow: hidden;
    }
    .block {
      width: 300px;
      float: left;
      margin: 0 30px 0 0;
      &.last {
        margin: 0;
      }
    }
    .desc {
      height: 140px;
      box-sizing: border-box;
    }
  }

  a {
    color: $green;
  }

  .read {
    position: relative;
    padding: 0 260px 0 0;
  }

  .icon {
    position: absolute;
    right: 20px;
    top: 10px;
  }

  h3.h3 {
    height: 40px;
    line-height: 40px;
    background: url(../img/service/shape.png) no-repeat;
    color: #fff;
    padding: 0 0 0 50px;
    margin: 0 0 30px;
  }

  .price-wrapper {
    position: relative;
    padding: 20px 0 0;
    .notes {
      padding: 25px 0 60px;
      font-size: 12px;
    }
  }

  .security {
    padding: 0 0 30px;
    .icon {
      position: absolute;
      top: 70px;
      left: 20px;
      padding: 0;
    }
    .desc {
      padding: 20px 0 0 260px;
    }
  }

}

#contact {

  &.form {

  }

  &.confirm {
    .value {
      height: 40px;
      line-height: 40px;
      &.multi {
        padding: 10px 0 0;
        height: auto;
        line-height: 1.8;
      }
      .selected {
        padding: 0 0 20px;
      }
    }

  }

  &.complete {
    .read {
      padding-bottom: 40px;
    }
  }

  .title {
    padding-top: 0;
  }

  .read {
    padding: 20px 0 0;
  }

  .banner {
    padding: 25px 0 50px;
  }

  .errors {
    padding: 20px 0;
    color: red;
    line-height: 1.5;
  }

  .require {
    width: 50px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    color: #fff;
    background: #e74f3e;
    font-size: 11px;
    display: inline-block;
    margin: 0 0 0 5px;
  }

  .agree {
    padding: 20px 0;
    .ib {
      display: inline-block;
    }
    .checkbox {
      margin: 0 5px 30px 0;
    }
    #checkbox {
      border: 1px solid #9f6947;
      transform: scale(1.5);
      margin: 0 5px 0 0;
      vertical-align: middle;
    }
    .txt a {
      color: red;
      text-decoration: underline;
    }
    .button {
      img {
        vertical-align: middle;
        margin: 0 8px 4px 0;
      }
    }
  }

  .search {
    display: inline-block;
    color: #fff;
    background-color: #9f6947;
    width: 80px;
    text-align: center;
    height: 36px;
    line-height: 36px;
    margin: 0 0 0 10px;
    cursor: pointer;
  }

  .submit {
    margin: 40px 0 10px;
  }
  .reset {
    cursor: pointer;
    text-align: center;
    text-decoration: underline;
    color: #9f6947;
  }

  .button {
    display: block;
    width: 380px;
    height: 60px;
    line-height: 60px;
    border-radius: 4px;
    margin: 0 auto;
    text-align: center;
    font-size: 21px;
    color: #fff;
    background-color: #9f6947;
    border: none;
  }

  .input {
    padding: 20px 0;
    overflow: hidden;
    border-bottom: 2px solid #d29a6b;
    &.last {
      border: none;
    }
  }

  .label {
    width: 250px;
    float: left;
    height: 40px;
    line-height: 40px;
  }
  .value {
    float: left;
    width: 610px;
  }
  .multi {
    margin: 0 0 20px;
  }

  .ips {
    box-sizing: border-box;
    border: 2px solid #9f6947;
    border-radius: 4px;
    line-height: 40px;
    height: 40px;
    padding: 0 0 0 10px;
  }

  .input600 {
    width: 600px;
    @extend .ips;
  }

  .input480 {
    width: 480px;
    @extend .ips;
  }

  .input320 {
    width: 320px;
    @extend .ips;
  }

  .input120 {
    width: 120px;
    @extend .ips;
  }

  select {
    height: 50px;
    line-height: 50px;
    //-webkit-appearance: none;
    //-moz-appearance: none;
    //-ms-appearance: none;
    appearance: none;
    width: 300px;
    //@extend .font-bold;
    font-size: 18px;
    padding: 0 0 0 20px;
    box-sizing: border-box;
    background: #9f6947 url(../img/common/arrow.png) right center no-repeat;
    border-radius: 0;
    border: none;
    color: #fff;
  }
  textarea {
    @extend .ips;
    width: 600px;
    height: 200px;
    line-height: 1.2;
    padding: 10px;
  }

}

#faq {

  position: relative;
  padding: 0 0 30px;
  .icon {
    padding: 0 0 20px;
  }
  .block {
    margin: 0 0 30px;
  }

  .label {
    width: 80px;
    float: left;
    padding: 0 0 0 10px;
    box-sizing: border-box;
  }
  .txt {
    float: left;
    width: 880px;
    line-height: 24px;
  }

  .ans {
    padding: 0 0 10px;
    overflow: hidden;
    .txt {
      color: #4e2901;
      font-weight: bold;
      font-size: 21px;
      line-height: 36px;
      height: 36px;
    }
  }

  .que {
    padding: 20px 0 0;
    overflow: hidden;
  }
}

#flow {
  .block {
    position: relative;
    overflow: hidden;
  }

  .flow-wrapper {
    box-sizing: border-box;
    padding: 0 20px;
  }

  .label {
    float: left;
    width: 300px;
  }
  .txt {
    float: left;
    width: 620px;
    display: table;
    height: 80px;
  }
  a {
    color: red;
    text-decoration: underline;
  }
  .ac {
    display: table-cell;
    vertical-align: middle;
  }
  .arrow {
    width: 280px;
    text-align: center;
    padding: 20px 0 20px;
  }
  .notice {
    padding: 40px 0 0;
  }
}

#sitemap {

  .sitemap-wrapper {
    padding: 0 20px;
    overflow: hidden;

    .block {
      margin: 0 0 15px;
    }

    .left {
      float: left;
      width: 460px;
      padding: 0 0 0 40px;
      box-sizing: border-box;
    }

    .right {
      float: left;
      width: 460px;
      padding: 0 0 0 20px;
      box-sizing: border-box;
    }

    .ul {
      padding: 15px 0 0 18px;
      list-style: disc;
      overflow: hidden;
      margin: 0 0 30px;
      &.small {
        margin: 0;
        li {
          width: 200px;
          float: left;
        }
      }
      li {
        margin: 0 0 8px;
        &.idn {
          margin: 0 0 8px 24px;
        }
      }
      a {
        text-decoration: underline;
        color: #50320a;
        font-weight: bold;
      }
    }

    .box {
      display: block;
      height: 60px;
      line-height: 54px;
      background: #fff;
      color: #9f6947;
      padding: 0 0 0 20px;
      width: 400px;
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 18px;
      font-weight: bold;
      border: 3px solid #9f6947;
    }
  }

}

#company {

  .ceo {
    text-align: right;
    padding: 20px 0 0;
  }

  .line {
    margin: 0 0 20px;
  }

  .left  {
    float: left;
    width: 120px;
  }
  .right {
    float: left;
    width: 840px;
  }

  .block {
    overflow: hidden;
    margin: 0 0 15px;
    line-height: 1.5;
  }

  .can {
    display: inline-block;
    width: 180px;
  }

  a {
    color: #4e2901;
    text-decoration: underline;
  }

  #outline {
    position: relative;
    padding: 0 0 60px;
    .outline-wrapper {
      width: 480px;
    }
    .right {
      width: 360px;
    }
    .graph {
      position: absolute;
      right: 0;
      top: -18px;
    }
  }

  #desc {
    position: relative;
    padding: 0 0 60px;
  }

  .logos {
    padding: 20px 0 0;
    p {
      display: inline-block;
    }
    .org {
      margin: 0 0 0 30px;
    }
  }

  .map {
    padding: 10px;
    background: #fff;
  }
  #map {
    width: 940px;
    height: 380px;
  }
}


#blog {
  position: relative;

  a {
    color: #ff0001;
    text-decoration: underline;
  }

  .date {
    font-size: 12px;
  }

  .title {
    padding: 5px 0;
  }

  .cnt {
    width: 680px;
    min-height: 400px;
  }
  .bot {
    margin: 0 0 20px;
  }
  .top {
    margin: 20px 0 0;
  }
  .body {
    line-height: 1.8;
  }

  .block {
    margin: 0 0 30px;
  }

  .paging {
    padding: 20px 0 0;
    text-align: center;
    .ib {
      display: inline-block;
    }
    a {
      color: #4e2901;
    }
  }

  .blog-nav {
    position: absolute;
    right: 0;
    top: 0;
    width: 238px;
    .box {
      margin: 0 0 30px;
    }
    .li {
      margin: 0 0 5px;
    }
    a {
      color: #9f6947;
      text-decoration: underline;
    }
  }
  .ob {
    height: 35px;
    line-height: 35px;
    padding: 0 0 0 10px;
    background: #9f6947;
    color: #fff;
    margin: 0 0 20px;
  }
  pre {
    word-wrap: break-word;
  }

}

#privacy {
  .read {
    padding: 30px 0 0;
  }
  .info {
    text-align: right;
  }
  .pg {
    padding: 0 0 30px;
    line-height: 1.8;
  }
  .closure {
    padding: 10px 0 20px;
    text-align: center;
  }
  h4 {
    font-weight: bold;
  }
  a {
    color: red;
    text-decoration: underline;
  }
}

#career {
  .icons {
    padding: 40px 0;
    text-align: center;
  }
  h3 {
    height: 40px;
    line-height: 40px;
    color: #fff;
    background: url(../img/common/circle-line.png) no-repeat;
    font-weight: bold;
    box-sizing: border-box;
    padding: 0 0 0 50px;
    margin: 0 0 5px;
  }
  .wrap {
    overflow:hidden;
    width: 960px;
  }
  .job {
    float: left;
    width: 460px;
    padding: 0 20px 0 0;
    margin: 0 0 40px;
  }
  .sub {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: bold;
  }

  .desc {
    padding: 8px 0;
    height: 80px;
  }

  .noc {
    border: 2px solid #d29a6b;
    text-align: center;
    height: 50px;
    line-height: 50px;
  }

}




@import "anime";


// LP load
@import "_lp";
